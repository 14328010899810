'use client'

import Image from "next/image";
import styles from "./speaker-grid-box.module.css";
import { useInView } from "framer-motion";
import { useRef } from "react";

const SpeakerGridBox = ({ image, speaker, desc, delay }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true});
  
  return (
    <div ref={ref} style={{
      opacity: isInView ? 1 : 0,
      translate: isInView ? 0 : 100,
      transition: 'all 1s ease',
      transitionDelay: delay,
    }} className={styles.gridBox}>
      <div className={styles.imageContainer}>
        <Image src={image} sizes="(max-width: 768px) 100vw, 40vw" alt="speaker" fill />
      </div>
      <h2>{speaker}</h2>
      {desc !== null && (<h3>{desc}</h3>)}
    </div>
  );
};

export default SpeakerGridBox;
