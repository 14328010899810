'use client'

import Button from "../ui/button/button";
import Image from "next/image";
import { motion } from "framer-motion";
import styles from "./intro.module.css";

const IntroSection = () => {
  return (
    <section className={styles.home}>
      <motion.div initial={{opacity: 0, x: 200, transitionDuration: 1}} animate={{opacity: 1, x: 0, transition: 'all 1s ease'}} className={styles.homeLeft}>
        <h2>Conversando con referentes internacionales del coaching</h2>
        <h4>Descubrí el valor de la especialización</h4>
        <div className={styles.eventInfo}>
          <Button title={"Quiero asistir"} link="/#inscripcion" />
          <div className={styles.dateInfo}>
            <time>13 de Diciembre, 2023</time>
            <address>Buenos Aires, Argentina</address>
          </div>
        </div>
      </motion.div>
      <div className={styles.homeRight}>
        <div style={{ width: "100%", height: "100%" }}>
          <Image
            src={"/images/home-vector.png"}
            alt="home"
            width={1000}
            height={1000}
            priority
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <div className={styles.svgRight}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="556"
          viewBox="0 0 1020 557"
          fill="none"
        >
          <path
            d="M356.715 236.053V334.339C356.715 361.954 334.329 384.339 306.715 384.339H51C23.3858 384.339 1 406.725 1 434.339V506C1 533.614 23.3857 556 51 556H969C996.614 556 1019 533.614 1019 506V51C1019 23.3858 996.614 1 969 1H576.221C548.607 1 526.221 23.3857 526.221 51V110.032V136.053C526.221 163.667 503.835 186.053 476.221 186.053H464.245H406.715C379.101 186.053 356.715 208.439 356.715 236.053Z"
            stroke="#181818"
          />
        </svg>
      </div>
    </section>
  );
};

export default IntroSection;
