"use client";

import styles from "./timer-countdown.module.css";
import { useState } from "react";

const TimerBox = ({ time, unit }) => {
  return (
    <div className={styles.timerBox}>
      <h4>{time}</h4>
      <p>{unit}</p>
    </div>
  );
};

const TimerCountdown = () => {
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  function zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + "";
  }

  const countdown = setInterval(function () {
    let countDownDate = new Date("Dec 13, 2023 09:00:00").getTime();
    let now = new Date().getTime();
    let timeleft = countDownDate - now;

    setDays(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, 0));
    setMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, 0));
    setSeconds(Math.floor((timeleft % (1000 * 60)) / 1000).toString().padStart(2, 0));

    if (timeleft < 0) {
      clearInterval()
      setDays('0')
      setHours('00')
      setMinutes('00')
      setSeconds('00')
    }
  }, 1000);

  return (
    <div className={styles.timerContainer}>
      <div className={styles.dayBox}>
        <TimerBox time={days} unit={"dias"} />
      </div>
      <div className={styles.hoursBox}>
        <TimerBox time={hours} unit={"horas"} />
        <p className={styles.timeSeparators}>:</p>
        <TimerBox time={minutes} unit={"minutos"} />
        <p className={styles.timeSeparators}>:</p>
        <TimerBox time={seconds} unit={"segundos"} />
      </div>
    </div>
  );
};

export default TimerCountdown;
