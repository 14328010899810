import(/* webpackMode: "eager" */ "/vercel/path0/app/page.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/intro/intro.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/stats/stats.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/talk-together/talk-together.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/button/button.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/timer/timer.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/event-feature/event-feature.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/payment/payment.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/speakers/speakers.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/sponsors/sponsors.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/event-schedule/event-schedule.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/location/location.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/image-timer/image-timer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/keen-slider/keen-slider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/schedule-box/schedule-box.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/speaker-grid-box/speaker-grid-box.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/stats-svg/stats-svg.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/timer-countdown/timer-countdown.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js")