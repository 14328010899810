"use client";

import { useEffect, useState } from "react";

import Image from "next/image";

const ImageTimer = () => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    width < 768 && setMobile(true);
  }, []);

  return (
    <>
      {!mobile && (
        <Image
          src={"/images/calendar.png"}
          width={222}
          height={222}
          alt="calendar"
        />
      )}
    </>
  );
};

export default ImageTimer;
