"use client";

import { HiMiniSignal } from "react-icons/hi2";
import Image from "next/image";
import styles from "./schedule-box.module.css";
import { useInView } from "framer-motion";
import { useRef } from "react";

const ScheduleBox = ({
  imageSpeaker,
  title,
  timeFrom,
  timeTo,
  about,
  isStreaming = false,
  country
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true});

  return (
    <div
      className={styles.scheduleBox}
      ref={ref}
      style={{
        opacity: isInView ? 1 : 0,
        transition: 'all 1.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s'
      }}
    >
      {isStreaming && (
        <div className={styles.isStreaming}>
          <p>Transmisión online desde {country}</p>
          <HiMiniSignal size={30} color="dd4137" />
        </div>
      )}
      <div className={styles.imageContainer}>
        <div className={styles.imageBox}>
          <Image src={imageSpeaker} alt="speaker" sizes="(max-width: 768x) 80vw, 40vw" fill />
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.timeInfo}>
          <time>{timeFrom}</time> a <time>{timeTo}</time>
        </p>
      </div>
      <div className={styles.aboutContainer}>
        <h4>{about}</h4>
      </div>
      <div className={styles.titleContainer}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default ScheduleBox;
