"use client";

import "keen-slider/keen-slider.min.css";

import Image from "next/image";
import styles from "./keen-slider.module.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";

function Arrow(props) {
  
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

const KeenSlider = () => {
  const [details, setDetails] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      detailsChanged(s) {
        setDetails(s.track.details);
      },
    },
    []
  );

  function scaleStyle(idx) {
    if (!details) return {};
    const slide = details.slides[idx];
    const scale_size = 0.6;
    const scale = 1 - (scale_size - scale_size * slide.portion);
    return {
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
    };
  }

  return (
    <>
      <div
        ref={sliderRef}
        className={`${styles.keenSlider} keen-slider zoom-out`}
      >
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(0)}>
            <Image
              src={"/images/damian-phrase.jpeg"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(1)}>
            <Image
              src={"/images/laura-phrase.png"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(2)}>
            <Image
              src={"/images/marco-phrase.png"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(3)}>
            <Image
              src={"/images/eloy-phrase.png"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(4)}>
            <Image
              src={"/images/jim-phrase.png"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
        <div
          className={`${styles.sliderItem} keen-slider__slide zoom-out__slide`}
        >
          <div style={scaleStyle(5)}>
            <Image
              src={"/images/elena-phrase.png"}
              alt="slider-image"
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
              fill
            />
          </div>
        </div>
      </div>
      <div className={'optionsGridKeenSlider'}>
      {loaded && instanceRef.current && (
        <>
          <Arrow
            left
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />

          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </>
      )}
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
      </div>
    </>
  );
};

export default KeenSlider;
